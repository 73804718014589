/* eslint-disable jsx-a11y/no-autofocus */

import React, { useState } from "react";
import { graphql } from "gatsby";

import SEO from "../components/SEO";
import "../components/FrontDoor.css";
import frontDoorBlueMetalSheet from "../images/front-door/front-door-blue-sheet-metal.jpg";
import FretlinkLogo from "../components/svg-assets/FretlinkLogo";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import app from "../lib/app";
import { ArrowLeft24, SuccessCircle } from "../components/svg-assets/Icons";

const VALID_EMAIL_PATTERN = /.+@.+\..+/;

const validateEmail = (t, email) => {
  const trimmedEmail = email?.trim() ?? "";

  if (trimmedEmail.length && !VALID_EMAIL_PATTERN.test(trimmedEmail)) {
    return t("login.errors.invalid-email");
  }
};

const resetForm = (t, email, setEmail, emailError, setEmailError, setSent) => {
  const fieldClassNames = erroneous =>
    Boolean(erroneous) ? "fd-field fd-field--erroneous" : "fd-field";

  const onSubmit = event => {
    event.preventDefault();

    const emailError = validateEmail(t, email);
    setEmailError(emailError);

    if (emailError) {
      return;
    } else {
      app()
        .lostPassword({
          email: email?.trim(),
        })
        .then(() => {
          setSent(true);
        })
        .catch(() => {
          setEmailError(t("lost-password.errors.unknown-error"));
        });
    }
  };

  return (
    <>
      <h2>{t("lost-password.title")}</h2>
      <p className="fd-instructions fd-instructions--narrow">{t("lost-password.instructions")}</p>
      <form action="#login" method="POST" onSubmit={onSubmit}>
        <label className={fieldClassNames(emailError)}>
          <span className="fd-label">{t("lost-password.form.login.label")}</span>
          <input
            type="email"
            id="email"
            value={email}
            onChange={event => {
              setEmailError(null);
              setEmail(event.target.value);
            }}
            onBlur={() => setEmailError(validateEmail(t, email))}
            autoFocus
            placeholder={t("lost-password.form.login.placeholder")}
          />
          {emailError ? <p className="fd-error">{emailError}</p> : undefined}
        </label>

        <input type="submit" value={t("lost-password.form.submit.title")} />
      </form>
    </>
  );
};

const confirmation = (t, email) => {
  return (
    <>
      <p>
        <SuccessCircle />
      </p>
      <p className="fd-instructions">{t("lost-password.confirmation.a-message-was-sent")}</p>
      <p className="fd-email-address-wrapper">
        <code>{email}</code>
      </p>
      <p className="fd-instructions">
        <Trans i18nKey="lost-password.confirmation.you-can-close-this-page" />
      </p>
    </>
  );
};

const LostPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [sent, setSent] = useState(false);

  return (
    <>
      <SEO title={t("lost-password.html-title")} />
      <div className="fd-front-door">
        <div className="fd-flap">
          {!sent ? (
            <Link to="/login/" className="fd-back-to-website">
              <ArrowLeft24 />
              {t("lost-password.go-back-to-login")}
            </Link>
          ) : undefined}
          <FretlinkLogo />
          {sent
            ? confirmation(t, email)
            : resetForm(t, email, setEmail, emailError, setEmailError, setSent)}

          <p className="fd-more-help">
            {/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */}
            <Trans
              i18nKey="lost-password.more-help.text"
              components={{
                email: <a href={`mailto:${t("lost-password.more-help.email")}`} />,
                phone: <a href={`tel:${t("lost-password.more-help.phone")}`} />,
              }}
            />
            {/* eslint-enable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content */}
          </p>
        </div>
        <div className="fd-illustration-flap">
          <img src={frontDoorBlueMetalSheet} alt="" loading="lazy" />
        </div>
      </div>
    </>
  );
};

export default LostPasswordPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
