const app = () => {
  const baseUrl = process.env.APP_API_BASE_URL.replace(/\/$/, "");

  const login = ({ email, password }) =>
    fetch(`${baseUrl}/auth`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          const error = Error();
          error.response = response;
          throw error;
        }
      })
      .catch(error => {
        console.error(error);
        throw error;
      });

  const isAuthenticated = () =>
    fetch(`${baseUrl}/accounts/whoami`, {
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    })
      .then(response => response.ok)
      .catch(() => false);

  const lostPassword = ({ email }) =>
    fetch(`${baseUrl}/forgottenPassword`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email }),
      headers: { "Content-Type": "application/json" },
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          const error = Error();
          error.response = response;
          throw error;
        }
      })
      .catch(error => {
        console.error(error);
        throw error;
      });

  return {
    login,
    isAuthenticated,
    lostPassword,
  };
};

export default app;
